import * as React from 'react'

const fixedNavbarHeight = 180

export const useDockBottom = (
  parentRef: React.RefObject<HTMLElement>,
  childRef: React.RefObject<HTMLElement>,
  padding = 0,
) => {
  const [leftOffset, setLeftOffset] = React.useState(0)
  const [rightOffset, setRightOffset] = React.useState(0)
  const [position, setPosition] = React.useState('')
  const [dockPosition, setDockPosition] = React.useState<'top' | 'bottom'>('bottom')
  React.useEffect(() => {
    const checkPosition = () => {
      if (!childRef.current || !parentRef.current) {
        return
      }

      const childRect = childRef.current.getBoundingClientRect()
      const parentRect = parentRef.current.getBoundingClientRect()
      const viewportHeight = window.innerHeight

      const parentDocumentTop = parentRef.current.offsetTop
      const isChildShorterThanParentVisiblePart =
        childRef.current.scrollHeight < viewportHeight - (20 + fixedNavbarHeight)
      const scrollBreakPointLong = isChildShorterThanParentVisiblePart
        ? Number.MAX_VALUE
        : Math.max(parentDocumentTop + childRect.height + 20, viewportHeight)

      const scrollBreakPointShort = isChildShorterThanParentVisiblePart
        ? parentDocumentTop + 20
        : Number.MAX_VALUE

      const currentScrollBottom = window.scrollY + viewportHeight

      if (currentScrollBottom >= parentDocumentTop + parentRect.height) {
        // We've scrolled past the parent bottom
        setPosition('absolute')
      } else if (window.scrollY > scrollBreakPointShort) {
        // We've scrolled past the top of the parent and the child is shorter than the parent visible portion
        setPosition('fixed')
        setLeftOffset(parentRect.left + padding)
        setRightOffset(parentRect.right + padding)
        setDockPosition('top')
      } else if (currentScrollBottom > scrollBreakPointLong) {
        // The bottom of the screen is lower than the child's bottom, but we're still in the parent
        setPosition('fixed')
        setLeftOffset(parentRect.left + padding)
        setRightOffset(parentRect.right + padding)
        setDockPosition('bottom')
      } else {
        // We're above the parent bottom
        setPosition('')
      }
    }

    checkPosition()
    window.addEventListener('scroll', checkPosition)
    window.addEventListener('resize', checkPosition)

    return () => {
      window.removeEventListener('scroll', checkPosition)
      window.removeEventListener('resize', checkPosition)
    }
  }, [parentRef, childRef, padding])

  // Apply calculated offset to the child
  React.useEffect(() => {
    if (childRef.current) {
      childRef.current.style.position = position
      if (position === 'fixed') {
        childRef.current.style.left = leftOffset + 'px'
        childRef.current.style.right = rightOffset + 'px'
        childRef.current.style.bottom = dockPosition === 'bottom' ? 20 + 'px' : ''
        childRef.current.style.top = dockPosition === 'top' ? 20 + fixedNavbarHeight + 'px' : ''
      } else {
        childRef.current.style.left = ''
        childRef.current.style.right = ''
        childRef.current.style.bottom = position === 'absolute' ? '0' : ''
        childRef.current.style.top = ''
      }
    }
  }, [childRef, position, leftOffset, rightOffset, dockPosition])
}
