'use client'
import * as React from 'react'
import { tw } from 'utils/classnames'
import { useTranslations } from 'next-intl'

import { UserAvatar } from '@ask-cf/components/avatar'
import { PostUser, ticketGenieUser } from '../feed/models'
import { StyledButton } from '@ask-cf/components/styled-button'
import { NewReleasesIcon } from '@ask-cf/assets/icons'
import { Topic } from '@ask-cf/constants/topics'
import { ASK_CF_FF_FOLLOW } from '@ask-cf/config'
import { TopicBadge } from '@ask-cf/components/topic-badge'
import { useFormatTimeSpan } from '@ask-cf/hooks/use-format-time-span'
import { UserNameLabel } from '../user-name-label'

export function PostHeader({
  user,
  topic,
  postedAt,
  isBestAnswerHeader,
}: {
  user: PostUser
  topic?: Topic
  postedAt?: string
  isBestAnswerHeader?: boolean
}) {
  const t = useTranslations('ask_cf.components.feed.post')
  const formatTimeSpan = useFormatTimeSpan()
  const timeElapsed = React.useMemo(
    () => postedAt && formatTimeSpan(new Date().getTime() - new Date(postedAt).getTime()),
    [postedAt, formatTimeSpan],
  )
  return (
    <div className="xl:flex xl:justify-between">
      <div className="flex items-center gap-2">
        <UserAvatar avatarUrl={user.avatarUrl} size="mediumSecondary" />
        <div className="grow xl:grow-0">
          <div className="flex items-center">
            <UserNameLabel className="text-text-primary text-sm font-semibold leading-5">
              {user.firstName} {user.lastName}
            </UserNameLabel>
            {timeElapsed ? (
              <>
                <span className="mx-0.5 text-xs font-medium leading-4 text-gray-600">&#x2022;</span>
                <span
                  className="text-xs font-medium leading-4 text-gray-600"
                  suppressHydrationWarning
                >
                  {timeElapsed}
                </span>
              </>
            ) : null}
          </div>
          <div className="text-xs leading-4 text-gray-600">{user.subtitle}</div>
        </div>
        {ASK_CF_FF_FOLLOW && user.id !== ticketGenieUser.id ? (
          <FollowButton hasSubtitle={!!user.subtitle} userId={user.id} />
        ) : null}
      </div>
      {topic ? (
        <div className="inline-block">
          <TopicBadge topic={topic} className="mt-2 xl:mt-0" />
        </div>
      ) : null}
      {isBestAnswerHeader ? (
        <div className="inline-block">
          <div className="mt-2 flex items-center gap-1 rounded-[12px] bg-[#FAE6E9] px-2 py-1 xl:mt-0">
            <NewReleasesIcon />
            <span className="text-xs font-medium uppercase leading-4">{t('best_answer')}</span>
          </div>
        </div>
      ) : null}
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function FollowButton({ userId, hasSubtitle }: { userId: string; hasSubtitle: boolean }) {
  const t = useTranslations('ask_cf.components.feed.post')
  return (
    <StyledButton
      noAnimation
      variant="transparent"
      size="minimal"
      className={tw(
        'text-xs font-medium leading-4 xl:pt-[1px]',
        hasSubtitle ? 'xl:self-start' : '',
      )}
    >
      {t('follow_button_text')}
    </StyledButton>
  )
}
