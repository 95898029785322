'use client'
import * as React from 'react'
import { motion } from 'framer-motion'
import { tw } from 'utils/classnames'

type StyledButtonProps = React.ComponentProps<typeof motion.button> & {
  variant?: 'blue' | 'pink' | 'fill' | 'transparent' | 'outline' | 'link' | 'gray'
  size?: 'minimal' | 'medium' | 'large'
  noAnimation?: boolean
  preventDefault?: boolean
}

export function StyledButton({
  variant = 'blue',
  size = 'large',
  noAnimation,
  disabled,
  preventDefault,
  onClick,
  ...props
}: StyledButtonProps) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (preventDefault) {
      e.preventDefault()
    }
    onClick?.(e)
  }
  return (
    <motion.button
      whileTap={{ scale: disabled || noAnimation ? 1 : 0.9 }}
      whileHover={{ scale: disabled || noAnimation ? 1 : 1.03 }}
      {...props}
      className={tw(
        'leading-6',
        'rounded-2 flex items-center justify-center gap-2',
        size === 'large' && 'text-md px-[18px] py-4',
        size === 'medium' && 'p-3 text-sm',
        size === 'minimal' && 'p-0 text-xs leading-4',
        variant === 'blue' && 'enabled:hover:bg-text-hover bg-blue-700 text-white',
        variant === 'gray' && 'bg-gray-100 text-gray-600 enabled:hover:bg-gray-300',
        variant === 'pink' && 'bg-pink-500 text-white enabled:hover:bg-pink-700',
        variant === 'fill' && 'text-text-primary bg-white enabled:hover:bg-blue-200',
        variant === 'outline' &&
          'enabled:hover:text-text-hover bg-white text-blue-700 shadow-[0px_1px_6px_0px_#00000024,_0px_0px_2px_0px_#0000001F]',
        variant === 'transparent' &&
          'text-content-text-link enabled:hover:text-text-hover bg-transparent hover:bg-transparent',
        variant === 'link' &&
          'text-text-tertiary text-md enabled:hover:text-text-hover gap-0 p-0 font-normal leading-6',
        disabled && 'cursor-not-allowed opacity-25',
        props.className,
      )}
      disabled={disabled}
      onClick={handleClick}
    >
      {props.children}
    </motion.button>
  )
}
