'use client'
import * as React from 'react'

import { ASK_CF_FF_LEADERBOARD } from '@ask-cf/config'
import { Leaderboard } from '../leaderboard'
import { UserProfilePanel } from '../user-profile-panel'
import { useDockBottom } from '@ask-cf/hooks/use-dock-bottom'
import { GoogleAd } from '../google-ads'

export function RightSidebar() {
  const parentRef = React.useRef<HTMLDivElement>(null)
  const childRef = React.useRef<HTMLDivElement>(null)
  useDockBottom(parentRef, childRef)

  return (
    <section
      ref={parentRef}
      className="tablet:block relative hidden w-[257px] shrink-0 self-stretch"
    >
      <div ref={childRef} className="flex w-[257px] flex-col gap-4">
        <UserProfilePanel />
        {ASK_CF_FF_LEADERBOARD ? <Leaderboard /> : null}
        <GoogleAd
          adClient="ca-pub-8093072971929641"
          adSlot="8149914147"
          style={{ display: 'inline-block', width: '250px', height: '250px' }}
        />
      </div>
    </section>
  )
}
