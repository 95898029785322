import * as React from 'react'
import throttle from 'lodash.throttle'

export const useIsOverflowing = (
  ref: React.RefObject<HTMLElement>,
  breakpointHeights?: number[],
) => {
  const [isOverflowing, setIsOverflowing] = React.useState<boolean[]>([])

  React.useEffect(() => {
    const checkOverflow = throttle(() => {
      if (ref.current && breakpointHeights) {
        const result = breakpointHeights.map(
          breakpointHeight =>
            !!ref.current &&
            ref.current.scrollHeight > (breakpointHeight ?? ref.current.clientHeight),
        )
        setIsOverflowing(result)
      }
    }, 400)

    checkOverflow()
    window.addEventListener('resize', checkOverflow)
    return () => window.removeEventListener('resize', checkOverflow)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])

  return isOverflowing
}
