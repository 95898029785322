import { UserRankType } from '@ask-cf/utils/user-ranks'
import TeddyBear from './assets/teddy-bear.png'
import CraftgenieAvatar from '@ask-cf/assets/images/craftgenie_avatar_xs.webp'
import Avatar1 from '@ask-cf/assets/images/avatar1.webp'
import Avatar2 from '@ask-cf/assets/images/avatar2.webp'
import Avatar4 from '@ask-cf/assets/images/avatar4.webp'
import Avatar6 from '@ask-cf/assets/images/avatar6.webp'

export interface Post {
  id: string
  user: PostUser
  topicSlug: string
  question: string
  context?: string | null
  reason?: string
  assets?: Asset[] | null
  answerCount?: number
  answers?: PostAnswer[] | null
  createTime: string
  isFollowed?: boolean
  questionSlug: string
}

export interface Asset {
  id: string
  url: string
  optimizedUrl?: string | null
  type: string
}

export interface PostUser {
  id: string
  firstName?: string | null
  lastName?: string | null
  displayName?: string | null
  avatarUrl?: string | null
  subtitle?: string | null
  rank?: UserRankType
}

export interface PostAnswer {
  id: string
  user?: PostUser
  answer: string
  upvotes: number
  downvotes: number
  comments?: PostComment[] | null
  isBestAnswer?: boolean
  isGenieAnswer?: boolean
  isTicketGenie?: boolean
  createTime: string
  assets?: Asset[] | null
}

export interface PostComment {
  id: string
  user: PostUser
  comment: string
  upvotes: number
  downvotes: number
  createTime: string
}

export const ticketGenieUser: PostUser = {
  id: 'craftgenie',
  firstName: 'Craftgenie',
  avatarUrl: CraftgenieAvatar.src,
  subtitle: 'AI BOT',
}

export const testItems: Post[] = [
  {
    id: 'abc',
    user: {
      id: 'abc',
      firstName: 'Danitscha Zieren',
      avatarUrl: Avatar1.src,
      subtitle: 'Bulletjournal & knitting lover',
    },
    topicSlug: 'fonts',
    question: 'How do I choose the right font for my project?',
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    assets: [],
    answerCount: 1,
    answers: [
      {
        id: 'abc',
        answer: `Selecting the perfect font for your project is a magical journey into typography that can greatly influence the mood and visual impact of your work. To help you choose the right font, here are some enchanting tips:
      1. Understand the Project's Tone: Consider what you want to communicate. A wedding invitation might call for an elegant script, while a tech start-up's website might lean towards a sleek, modern sans-serif.

      2. Readability is Key: If your project involves a lot of text, like a book or a blog post, choose a font that's easy to read. A well-spaced serif or sans-serif font can work wonders here.

      3. Audience Engagement: Think about the audience. What will appeal to them? Fonts can be funky, serious, or playful, so choose one that aligns with your audience's expectations.

      4. Contrast for Attention: Use contrasting fonts for headings and body text to create hierarchy and focus. Just ensure they complement each other well.

      5. Be Wary of Trends: While it's tempting to jump on the latest font trend, consider whether it will still look good in a few years. Timeless over trendy is often the way to go, unless you're sure the project calls for a contemporary edge.

      6. Limit the Number of Fonts: Using too many different fonts can create a chaotic and unprofessional look. Stick to 2-3 fonts that harmonize to keep your design cohesive.

      7. Test Out Fonts: Don't be afraid to test multiple fonts and see how they feel within the context of your project. Sometimes the right font choice becomes clear only when you see it in action.

      And don't forget about Creative Fabrica's Fontcloud, a magical tool that can help organize and visualize your fonts! It's there to help you manage and test different typography styles with ease, ensuring you make the most enchanting choice for your project. 📜✨`,
        upvotes: 0,
        downvotes: 0,
        comments: [],
        isTicketGenie: true,
        createTime: '2021-08-01T12:00:00Z',
      },
    ],
    createTime: '2021-08-01T12:00:00Z',
    isFollowed: true,
  },
  {
    id: 'abcd',
    user: {
      id: 'abc',
      firstName: 'Nick',
      avatarUrl: Avatar2.src,
      subtitle: 'Star Wars Crafts',
    },
    topicSlug: 'paper-crafting',
    question: 'How do I choose the right adhesive for different paper crafting projects?',
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    context:
      'I want to try out some of the 3D SVGs from Creative Fabrica, specifically this Valentine Bear https://www.creativefabrica.com/product/valentines-teddy-bear-paper-sculpture/ and I’m wondering what kind of glue will best keep the edges securely together.',
    assets: [{ url: TeddyBear.src, optimizedUrl: TeddyBear.src, id: '', type: 'image' }],
    answerCount: 2,
    answers: [
      {
        id: 'abcd',
        answer: ` When venturing into the wondrous world of paper crafting with 3D SVG files, like the Valentine Bear from Creative Fabrica, choosing the right adhesive is crucial to ensure your craft maintains its structure and charm. Here’s a sprinkle of guidance on picking the perfect adhesive for your paper sculpture:

1. PVA Glue: Polyvinyl acetate (PVA) glue, often known as craft glue or school glue, is excellent for paper projects. It dries clear and provides a flexible yet strong bond.

2. Glue Stick: For less intricate areas or when working with children, a glue stick can be sufficient. It's clean and easy to control, but may not hold as strongly as other adhesives.

3. Double-Sided Tape: This can be quite handy for quick, clean adhesion and works well for joining edges together in a 3D project.

4. Specialty Paper Glue: Some adhesives are specifically formulated for paper crafts. They tend to be thicker than PVA glue and may set more quickly, helping to maintain the shape of 3D components without warping.

5. Hot Glue Gun: For a faster setting option and a firm hold, a hot glue gun might be suitable, especially for the structural parts of a 3D paper sculpture. However, be cautious with the amount used as it can cause the paper to bulge or warp.

6. When working with your Valentine Bear, or any delicate 3D paper project, you’ll want an adhesive that sets relatively quickly and won’t cause the paper to become soggy or warp. Precise application tools, like fine-tip nozzles, are incredibly helpful for applying the right amount of glue without mess. And always allow for appropriate drying time based on the adhesive used to ensure the best hold.

For such a beautiful project from Creative Fabrica, I'd lean towards recommending either a thin PVA glue with a fine-tip applicator or a specialty paper glue for an optimal balance between strength and precision. 🐻✨

Remember to be patient as you assemble your bear, allowing each joint to dry sufficiently before proceeding to the next. Happy crafting! 🎨`,
        upvotes: 0,
        downvotes: 0,
        comments: [],
        isTicketGenie: true,
        isBestAnswer: true,
        createTime: '2021-08-01T12:00:00Z',
      },
      {
        id: 'abcde',
        user: {
          id: 'abc',
          firstName: 'Danitscha Zieren',
          avatarUrl: Avatar1.src,
          subtitle: 'Bulletjournal & knitting lover',
        },
        upvotes: 0,
        downvotes: 0,
        comments: [],
        answer:
          'For this project a glue stick or double sided tape is recommended. My personal preference is to use double sided tape but it can be tricky to get it stuck on right the first time (there are no second chances).',
        createTime: '2021-08-01T12:00:00Z',
      },
    ],
    createTime: '2021-08-01T12:00:00Z',
    reason: 'New answer to a question you follow',
  },
  {
    id: 'abcde',
    user: {
      id: 'abc',
      avatarUrl: Avatar4.src,
    },
    topicSlug: 'tools',
    question: 'What crafting tools and supplies can you not live without?',
    questionSlug: 'what-crafting-tools-and-supplies-can-you-not-live-without',
    assets: [],
    answerCount: 0,
    answers: [],
    createTime: '2021-08-01T12:00:00Z',
  },
  {
    id: 'abcdef',
    user: {
      id: 'abc',
      firstName: 'Piotr',
      avatarUrl: Avatar6.src,
      subtitle: 'Cat Paintings',
    },
    topicSlug: 'graphics',
    question: "What's the difference between raster and vector graphics?",
    questionSlug: 'whats-the-difference-between-raster-and-vector-graphics',
    assets: [],
    answerCount: 1,
    answers: [
      {
        id: 'abcdefg',
        answer: `TicketGenie Answer: Ah, the canvas of digital design is home to two types of magical illustrations: raster graphics and vector graphics, each with its own spellbinding properties and uses in the realm of creativity.

        Raster Graphics:

        Comprised of pixels, tiny squares of color that create the overall image – think of it as a mosaic.

        Resolution-dependent; their quality is tied to their pixel density, known as dpi (dots per inch). Scaling up from their original size can lead to blurriness or pixelation.

        Ideal for complex, color-rich images such as photographs where subtle gradations of color are necessary.

        Vector Graphics:

        Constructed using mathematical formulas instead of pixels that define points, lines, curves, and shapes called paths.

        Resolution-independent; they can be scaled to any size without losing quality, making them perfect for logos and other designs that need to be resized frequently.

        Often have a more graphic and less photo-realistic look due to their flat color areas and clean lines.

        In the digital crafting domain, vector files are often the preferred choice for projects that require cutting or resizing, such as when using cutting machines like SVG files from Creative Fabrica, or creating designs for various products that come in different sizes.

        When it comes to editing or converting, Creative Fabrica has some powerful sorcery in its arsenal with tools like the Webfont Generator for creating fonts from vector graphics, or the Vectorizer for converting raster graphics into cutting files. These tools ensure that crafters and designers alike have the enchantment needed to work fluidly between these two graphic realms. 🌟🎨`,
        upvotes: 0,
        downvotes: 0,
        comments: [],
        isTicketGenie: true,
        createTime: '2021-08-01T12:00:00Z',
      },
    ],
    createTime: '2021-08-01T12:00:00Z',
  },
]
