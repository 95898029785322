'use client'
import * as React from 'react'
import Script from 'next/script'

interface GoogleAdProps {
  adClient: string
  adSlot: string
  adFormat?: string
  fullWidthResponsive?: boolean
  style?: React.CSSProperties
  layoutKey?: string
}

export function GoogleAd({
  adClient,
  adSlot,
  style = { display: 'block' },
  adFormat,
  layoutKey,
}: GoogleAdProps) {
  const adRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const checkAdStatus = () => {
      if (adRef.current) {
        const adStatus = adRef.current.querySelector('ins')?.getAttribute('data-ad-status')
        if (!adStatus || adStatus === 'unfilled') {
          adRef.current.style.display = 'none'
        }
      }
    }

    // Check the ad status after it has had time to load
    const timeoutId = setTimeout(checkAdStatus, 2000) // Delay to ensure ad status is updated

    return () => clearTimeout(timeoutId)
  }, [])

  return (
    <div ref={adRef}>
      <Script
        async
        src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${adClient}`}
        crossOrigin="anonymous"
      />
      <ins
        className="adsbygoogle"
        style={style}
        data-ad-client={adClient}
        data-ad-slot={adSlot}
        {...(adFormat && { 'data-ad-format': adFormat })}
        {...(layoutKey && { 'data-ad-layout-key': layoutKey })}
      ></ins>
      <Script id="adsbygoogle-init">{`(adsbygoogle = window.adsbygoogle || []).push({});`}</Script>
    </div>
  )
}
