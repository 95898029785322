/* eslint-disable @next/next/no-img-element */
import { useTranslations } from 'next-intl'
import { IconButton } from 'primitives/buttons/icon-button'

import { PersonAddIcon } from '@ask-cf/assets/icons'
import BlueHollowStarImage from '@ask-cf/assets/images/blue_hollow_star.svg'
import PinkHollowStarImage from '@ask-cf/assets/images/pink_hollow_star.svg'
import PinkStarImage from '@ask-cf/assets/images/pink_star.svg'
import { userRanks } from '@ask-cf/utils/user-ranks'
import { UserAvatar } from '../avatar'
import { UserNameLabel } from '../user-name-label'
import { LeaderboardUser, testUsers } from './models'

export function Leaderboard({ fullScreen }: { fullScreen?: boolean }) {
  const t = useTranslations('ask_cf.components.leaderboard')
  return (
    <div className="relative flex flex-col rounded-[12px] bg-white p-4 pr-3">
      <header>
        <h2 className="text-text-secondary text-sm font-medium leading-5">{t('title')}</h2>
        <div className="mt-1 pr-[78px] text-xs leading-4 text-gray-600">{t('subtitle')}</div>
      </header>
      <div className="mt-4">
        {testUsers.map((user, index) => (
          <LeaderboardUserItem key={user.id} user={user} index={index} fullScreen={fullScreen} />
        ))}
      </div>
      <StarIcons />
    </div>
  )
}

function LeaderboardUserItem({
  user,
  index,
  fullScreen,
}: {
  user: LeaderboardUser
  index: number
  fullScreen?: boolean
}) {
  const t = useTranslations('ask_cf.components.leaderboard')
  const currentRankData = user.rank ? userRanks[user.rank] : null
  const BadgeIcon = currentRankData?.badgeIcon
  return (
    <div className="flex items-center pb-3.5 pt-2.5">
      <div className="text-text-tertiary mr-[3px] w-[14px] text-sm font-medium leading-5">
        {index + 1}
      </div>
      <div className="relative mr-2 flex items-center">
        <UserAvatar avatarUrl={user.avatarUrl} size="mediumTertiary" />
        {BadgeIcon ? (
          <BadgeIcon
            className={'absolute -bottom-[4.5px] -right-[3px]'}
            style={{ color: currentRankData.color }}
          />
        ) : null}
      </div>
      <div className="flex flex-col items-start justify-center">
        <UserNameLabel className="text-text-tertiary text-sm font-medium leading-5">
          {user.name}
        </UserNameLabel>
        <span className="text-text-quinary leading=[16.8px] text-xs font-medium">
          {t('best_answers_label', { answersCount: user.answerCount })}
        </span>
      </div>
      <IconButton
        className="group ml-auto bg-transparent hover:bg-transparent active:bg-transparent p-0"
        aria-label={t('follow')}
        asChild
      >
        <div>
          {fullScreen ? (
            <span className="text-content-text-link tablet:inline group-hover:text-text-hover mr-1.5 hidden text-sm">
              {t('follow')}
            </span>
          ) : null}
          <PersonAddIcon className="text-content-text-link group-hover:text-text-hover" />
        </div>
      </IconButton>
    </div>
  )
}

function StarIcons() {
  return (
    <>
      <img
        loading="lazy"
        className="absolute right-[22.45px] top-4"
        src={PinkHollowStarImage.src}
        width="13"
        height="13"
        alt=""
      />
      <img
        loading="lazy"
        src={BlueHollowStarImage.src}
        className="absolute right-[7.33px] top-[36.02px]"
        width="15"
        height="16"
        alt=""
      />
      <img
        loading="lazy"
        src={PinkStarImage.src}
        className="absolute right-[26px] top-[21px]"
        width="33"
        height="33"
        alt=""
      />
    </>
  )
}
