import { UserRankType } from '@ask-cf/utils/user-ranks'
import Avatar1 from '@ask-cf/assets/images/avatar1.webp'
import Avatar2 from '@ask-cf/assets/images/avatar2.webp'
import Avatar4 from '@ask-cf/assets/images/avatar4.webp'
import Avatar6 from '@ask-cf/assets/images/avatar6.webp'
import Avatar8 from '@ask-cf/assets/images/avatar8.webp'

export interface LeaderboardUser {
  id: string
  name?: string
  answerCount: number
  rank?: UserRankType
  avatarUrl?: string
}

export const testUsers = [
  {
    id: '1',
    name: 'Patricia Sanders',
    answerCount: 25,
    rank: UserRankType.CreativeNewbie,
    avatarUrl: Avatar1.src,
  },
  {
    id: '2',
    name: 'Frances Swan',
    answerCount: 24,
    rank: UserRankType.CuriousCrafter,
    avatarUrl: Avatar2.src,
  },
  {
    id: '3',
    name: 'James Hall',
    answerCount: 23,
    rank: UserRankType.CleverCrafter,
    avatarUrl: Avatar4.src,
  },
  {
    id: '4',
    name: 'Corina McCoy',
    answerCount: 22,
    rank: UserRankType.SkillfulCrafter,
    avatarUrl: Avatar6.src,
  },
  {
    id: '5',
    name: 'Stephanie Nicol',
    answerCount: 21,
    rank: UserRankType.MasterMaker,
    avatarUrl: Avatar8.src,
  },
  {
    id: '6',
    name: 'Iva Ryan',
    answerCount: 20,
    rank: UserRankType.CreativeMentor,
    avatarUrl: Avatar1.src,
  },
  {
    id: '7',
    name: 'Autumn Philips',
    answerCount: 19,
    rank: UserRankType.CraftHero,
    avatarUrl: Avatar2.src,
  },
  {
    id: '8',
    name: 'Daniel Hamilton',
    answerCount: 18,
    rank: UserRankType.CraftMaster,
    avatarUrl: Avatar4.src,
  },
  {
    id: '9',
    name: 'Katie Sims',
    answerCount: 17,
    rank: UserRankType.CraftGenius,
    avatarUrl: Avatar6.src,
  },
  {
    id: '10',
    name: 'Dennis Callis',
    answerCount: 16,
    rank: UserRankType.CreativeNewbie,
    avatarUrl: Avatar8.src,
  },
]
