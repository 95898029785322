import * as React from 'react'
import { tw } from 'utils/classnames'

import { Link } from '@ask-cf/components/link'
import { useIsOverflowing } from '@ask-cf/hooks/use-is-overflowing'

export function FadingOverflowContainer({
  children,
  viewMoreHref,
  viewMoreLabel,
  className,
}: {
  children: React.ReactNode
  viewMoreHref: string
  viewMoreLabel: string
  className?: string
}) {
  const contextRef = React.useRef(null)
  const [
    isOverflowing2LinesMobile,
    isOverflowing2LinesDesktop,
    isOverflowing3LinesMobile,
    isOverflowing3LinesDesktop,
  ] = useIsOverflowing(contextRef, [40, 48, 60, 72])

  const isOverflowingMobile = isOverflowing2LinesMobile || isOverflowing3LinesMobile
  const isOverflowingDesktop = isOverflowing2LinesDesktop || isOverflowing3LinesDesktop

  return (
    <div className={className}>
      <div
        className={tw(
          'text-text-context relative max-h-[60px] overflow-hidden text-sm leading-5 [overflow-wrap:anywhere]',
          isOverflowing2LinesMobile && !isOverflowing3LinesMobile ? 'max-h-[40px]' : 'max-h-[60px]',
          isOverflowing2LinesDesktop && !isOverflowing3LinesDesktop
            ? 'xl:max-h-[48px]'
            : 'xl:max-h-[72px]',
        )}
        ref={contextRef}
      >
        <div
          className={tw(
            'z-2 absolute h-full w-full bg-gradient-to-b from-transparent to-white',
            isOverflowingMobile ? 'block' : 'hidden',
            isOverflowingDesktop ? 'xl:block' : 'xl:hidden',
          )}
        />
        {children}
      </div>
      <Link
        href={viewMoreHref}
        className={tw(
          'text-content-text-link hover:text-text-hover text-sm leading-5',
          isOverflowingMobile ? 'block' : 'hidden',
          isOverflowingDesktop ? 'xl:block' : 'xl:hidden',
        )}
        target="_blank"
      >
        {viewMoreLabel}
      </Link>
    </div>
  )
}
